import Layout from "../components/layout"
import React from "react"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page not found" />
    <section>
      <div className="container">
        <div className="section--header with-top-padding">
          <h1 style={{ fontSize: "8rem" }}>404</h1>
          <h1>NOT FOUND</h1>
          <p>
            You just hit a page that doesn&#39;t exist...maybe you want to head
            back to the <a href="/">home page?</a>.
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
